import React, { PropsWithChildren } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import Strings from "@/shared/utils/Strings.constants";

interface CustomToastProps {
    closeToast: () => void;
    isCloseDisabled?: boolean;
}

export const Toast: React.FC<PropsWithChildren<CustomToastProps>> = ({ children, closeToast, isCloseDisabled }) => {
    return (
        <div className="flex gap-1 rounded-lg bg-background-success p-4 shadow-lg">
            {children}
            <button
                onClick={closeToast}
                className="flex size-5 shrink-0 items-center justify-center disabled:cursor-not-allowed"
                aria-label={Strings.CLOSE_NOTIFICATION}
                disabled={isCloseDisabled}
            >
                <FontAwesomeIcon icon={faClose} className="text-content-secondary" height="16px" width="16px" />
            </button>
        </div>
    );
};

Toast.displayName = "Toast";
